<template>
  <div>
    <manual-email-table v-if="isTable"></manual-email-table>
    <manual-email-form v-else></manual-email-form>
  </div>
</template>
<script>
export default {
  name: "CampaignPanelEmail",
  components: {
    ManualEmailTable: () => import("../BulkEmails"),
    ManualEmailForm: () => import("../BulkEmailForm/BulkEmailForm"),
  },
  data() {
    return {
      isTable: true,
    };
  },
  computed: {},
  watch: {},
  methods: {
    changeTab(isTable) {
      this.isTable = isTable;
    },
  },
  mounted() {},
  created() {
    this.$root.$refs.bulkEmail = this;
  },
};
</script>
